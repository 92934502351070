import { combineReducers } from "redux";
import employers from "./employers";
import files from "./files";
import jobs from "./jobs";
import recruiters from "./recruiters";
import users from "./users";
import conversations from "./conversations";
import auth from "../Auth/reducer";
import candidates from "./candidates";
import candidatesForAdmin from "./candidatesForAdmin";
import accountReducer from "./account";
import customCodes from "./customCodes";
import retailTerms from "./retailTerms";

export default combineReducers({
  employers,
  files,
  jobs,
  recruiters,
  auth,
  users,
  conversations,
  candidates,
  candidatesForAdmin,
  accountReducer,
  customCodes,
  retailTerms
});
